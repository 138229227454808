/* Switch.css */
.switch {
    width: 80px;
    height: 25px;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 40px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box;
}


.switch-handle {
    width: 23px;
    height: 23px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    transition: left 0.3s ease;
}

.switch-on .switch-handle {
    left: 49px;
}

.switch-off .switch-handle {
    left: 2px;
}

.switch-text {
    color: white;
    font-size: 12px;
    z-index: 1;
    font-weight: bold;
}

.switch-off .switch-text {
    padding-left: 23px;
}